//Legacy Colors
$white: #FFFFFF;
$black: #000000;
$gray-900: #141414;
$gray-800: #1F1F1F;
$gray-700: #333333;
$gray-600: #545454;
$gray-500: #757575;
$gray-400: #AFAFAF;
$gray-300: #CBCBCB;
$gray-200: #E2E2E2;
$gray-150: #EBEBEB;
$gray-165: #ECECEC;
$gray-100: #EEEEEE;
$gray-50: #F6F6F6;
$true-white: #FFFFFF;

$green-50: #E6F2ED;
$green-300: #05944F;

$dark-grey: #77787F;
$grey: #D9D9D9;
$soothe-teal: #67D3CC;
$soothe-teal-dark: #52A7A2;
$val-purple: #ADA0BF;
$error-red: #ff0000;
$red-400: #C11500;
$green: #05944F;

//Refresh Colors
$accent-light: #F1F6FF;
$lightest-blue: #CFDEFF;
$cove: #7089BE;
$purple-border-color: #6F88C0;
$lavendar: #898DA5;
$deep-blue: #586B94;
$deep-orange: #D9664F;
$cactus-flower: #F58266;
$sherbert: #FFC4B5;
$peach: #FEEDE9;
$nickle: #5F6368;
$faded-black: #5D6369;
$blaak: #39455E;
$bluish-black: #2E364D;
$soft-black: #2C3239;
$bayou: #4A5C73;
$gray: #BDC3CA;
$lightest-gray: #F9F9F9;
$lavendar-ash: #464A5B;
$lavendar-dark: #5F637A;
$lavendar-subtle: #F2F4FF;

// States colors
$border-state-border-negative: #F1998E;
$background-state-negative-light: #FFEFED;

$gotham-htf-bold: 'gotham-htf-bold', sans-serif;
$gotham-htf-book: 'gotham-htf-book', sans-serif;
$gotham-htf-book-italic: 'gotham-htf-book-italic', sans-serif;
$gotham-htf-light: 'gotham-htf-light', sans-serif;
$gotham-htf-light-italic: 'gotham-htf-light-italic', sans-serif;
$gotham-htf-medium: 'gotham-htf-medium', sans-serif;
$gotham-htf-bold-condensed: 'gotham-htf-bold-condensed', sans-serif;
$gotham-htf-bold-italic: 'gotham-htf-bold-italic', sans-serif;

$poppins-bold: 'Poppins-Bold', sans-serif;
$poppins-light: 'Poppins-Light', sans-serif;
$poppins-medium: 'Poppins-Medium', sans-serif;
$poppins-regular: 'Poppins-Regular', sans-serif;
$poppins-semi-bold: 'Poppins-SemiBold', sans-serif;